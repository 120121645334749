<template>
  <component :is="wpItemData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="wpItemData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching {{ $props.contentName }} data
      </h4>
      <div class="alert-body">
        No {{ $props.contentName }} found with this {{ $props.contentNameSingular }} id. Check
        <b-link
          class="alert-link"
          :to="{ name: $props.contentType}"
        >
          {{ $props.contentName }} List
        </b-link>
        for other reports.
      </div>
    </b-alert>


    <wp-items-edit-tab-overview
      v-if="wpItemData"
      :wp-item-data="wpItemData"
      class="mt-2 pt-75"
    />

  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import WpItemsEditTabOverview from './WpItemsEditTabOverview.vue'
import WpItemsEditTabPermissions from './WpItemsEditTabPermissions.vue'

import ReportsEditTabContent from '../../report/reports-edit/ReportsEditTabContent.vue'
import GalleriesEditTabContent from '../../gallery/galleries-edit/GalleriesEditTabContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    WpItemsEditTabOverview,
    WpItemsEditTabPermissions,
    ReportsEditTabContent,
    GalleriesEditTabContent,
  },
  props: {
    contentType: {
      type: String,
      required: true,
    },
    contentTypeSingular: {
      type: String,
      required: true,
    },
    contentName: {
      type: String,
      required: true,
    },
    contentNameSingular: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const wpItemData = ref(null)
    const orgOptions = ref(null)

    store.dispatch('app/getWpItem', {
      contentType: props.contentType,
      id: router.currentRoute.params.id,
    })
      .then(response => {
        const item = response.data.results[0]
        // collapse organisations object array down to an array of IDs
        item.organisations = item.organisations.map(({ id }) => id)
        // collapse topics object array down to an array of IDs
        item.topics = item.topics.map(({ id }) => id)
        // change event object to event id
        if (item.parent_event != null) item.parent_event = item.parent_event.id
        // set user id
        if (item.owner) {
          item.user_id = item.owner.id
        } else {
          item.owner = { id: null, name: null, photo_url: null }
        }

        wpItemData.value = item
      })
      .catch(error => {
        // if (error.response.status === 404) {
        //   wpItemData.value = undefined
        // }
        console.log(error)
      })

    return {
      wpItemData,
      orgOptions,
    }
  },
}
</script>

<style>

</style>
