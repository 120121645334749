<template>
  <div>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <!-- WpItem Info: Input Fields -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <h4>{{ wpItemData.title }}</h4>
        <!-- TODO: eslint-disable-next-line vue/no-v-html -->
        <div v-html="wpItemData.excerpt" />
        <b-button
          :href="'https://digitalworkplacegroup.com/wp-admin/post.php?action=edit&post=' + wpItemData.wp_id"
          target="_blank"
          variant="primary"
          class="mt-2"
        >
          Edit content in WordPress
        </b-button>
        <b-row class="mt-3">
          <!-- Field: Featured -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Featured"
              label-for="featured"
            >
              <b-form-checkbox
                id="featured"
                v-model="wpItemData.featured"
                value="1"
                unchecked-value="0"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="$themeConfig.app.allowShowInFeed.includes(wpItemData.content_type)">
          <!-- Field: Show in news -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Show In News"
              label-for="show-in-news"
            >
              <b-form-checkbox
                id="show-in-news"
                v-model="wpItemData.news"
                value="1"
                unchecked-value="0"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- Field: Topics -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Topics"
            >
              <b-form-group
                label="Topics"
                label-for="topics"
              >
                <v-select
                  v-model="wpItemData.topics"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="topicOptions"
                  :state="getValidationState(validationContext)"
                  :reduce="val => val.value"
                  multiple
                  input-id="topics"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>


        <b-row class="d-none">
          <!-- Field: Categories -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="category"
            >
              <b-form-group
                label="Category"
                label-for="categorry"
              >
                <v-select
                  v-model="wpItemData.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoryOptions"
                  :state="getValidationState(validationContext)"
                  taggable
                  pushTags
                  input-id="category"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Orgs -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Organisations"
            >
              <b-form-group
                label="Organisations"
                label-for="orgs"
              >
                <v-select
                  v-model="wpItemData.organisations"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="orgOptions"
                  :state="getValidationState(validationContext)"
                  :reduce="val => val.value"
                  multiple
                  input-id="orgs"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Event -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Belongs to event"
            >
              <b-form-group
                label="Belongs to event"
                label-for="event"
              >
                <v-select
                  v-model="wpItemData.parent_event"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="eventOptions"
                  :state="getValidationState(validationContext)"
                  :reduce="val => val.value"
                  input-id="event"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="d-none">
          <!-- Field: Tags -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="validationContext"
              name="Tags"
            >
              <b-form-group
                label="Search keywords"
                label-for="tags"
              >
                <b-form-tags
                  v-model="wpItemData.tags"
                  input-id="tags"
                  separator=","
                  remove-on-delete
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          v-if="!working"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Save Changes
        </b-button>
        <b-button
          v-if="!working"
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :to="{ name: contentType + '-view', params: { id: wpItemData.id } }"
        >
          Cancel
        </b-button>
        <b-button
          v-if="working"
          disabled
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          <feather-icon
            icon="RefreshCwIcon"
            animation="spin"
          /> Updating...
        </b-button>
      </b-form>

    </validation-observer>
  </div>
</template>

<script>
import {
  BAvatar, BButton, BRow, BCol, BFormGroup, BFormTags,
  BForm, BFormInvalidFeedback, BCardText, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

import { $themeConfig } from '@themeConfig'

import useWpItemsList from '../wp-items-list/useWpItemsList'

export default {
  components: {
    BFormCheckbox,
    BAvatar,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormTags,
    BForm,
    BFormInvalidFeedback,
    vSelect,
    VueAutosuggest,
    BCardText,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    wpItemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
      debounceMilliseconds: 250,
      selected: null,
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
      },
      limit: 10,
    }
  },
  methods: {
    getSuggestionValue(suggestion) {
      return suggestion.item.name
    },
    onSelected(option) {
      this.selected = option.item
      this.$props.wpItemData.user_id = option.item.id
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        this.datasuggest = undefined
        return
      }

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        store.dispatch('app/fetchUsers', {
          q: text,
          sortBy: 'name',
        })
          .then(response => {
            this.filteredOptions = [{
              data: response.data.results,
            }]
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.datasuggest = undefined
            }
          })
      }, this.debounceMilliseconds)
    },
  },
  setup(props) {
    // Use toast
    const toast = useToast()
    const wpContentTypeName = ref(router.currentRoute.name)

    const { statusOptions } = useWpItemsList()

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const contentType = router.currentRoute.name.split('-edit')[0]
    const orgOptions = ref([])
    const categoryOptions = ref([])
    const topicOptions = ref([])
    const eventOptions = ref([])
    const working = ref(false)


    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.wpItemData.avatar = base64
    })

    // Convert name from the route to a readable WP item type
    const contentTypeName = routeName => {
      if (routeName === 'news-articles-edit') return 'News Article'
      if (routeName === 'kb-articles-edit') return 'Knowledge Base Article'
      if (routeName === 'research-articles-edit') return 'Research Article'
      return 'WP Item'
    }

    // Convert name from the route to a route name for WP item single view
    const contentTypeSingleRouteName = routeName => {
      if (routeName === 'news-articles-edit') return 'news-articles-view'
      if (routeName === 'kb-articles-edit') return 'kb-articles-view'
      if (routeName === 'research-articles-edit') return 'research-articles-view'
      return 'news-articles-view' // TODO: Better default?
    }

    // Populate the orgsOptions list
    store.dispatch('app/fetchOrgs', { perPage: 1000, sortBy: 'name' })
      .then(response => {
        orgOptions.value = response.data.results.map(item => ({ label: item.name, value: item.id }))
      })
      .catch(error => {
        if (error.response.status === 404) {
          orgOptions.value = undefined
        }
      })

    store.dispatch('app/listContentItems', { contentType: 'events', queryParams: { perPage: 1000, sortBy: 'name' } })
      .then(response => {
        eventOptions.value = response.data.results.map(item => ({ label: item.title, value: item.id }))
      })
      .catch(error => {
        if (error.response.status === 404) {
          eventOptions.value = undefined
        }
      })

    store.dispatch('app/fetchTopics', { perPage: 1000, sortBy: 'name' })
      .then(response => {
        topicOptions.value = response.data.results.map(item => ({ label: item.name, value: item.id }))
      })
      .catch(error => {
        if (error.response.status === 404) {
          topicOptions.value = undefined
        }
      })

      store.dispatch('app/fetchCategories', { contentType, queryParams: {perPage: 1000, sortBy: 'name' } })
      .then(response => {
        categoryOptions.value = response.data.results.map(item => ({ label: item.category, value: item.category }))
      })
      .catch(error => {
        if (error.response.status === 404) {
          categoryOptions.value = undefined
        }
      })

    const onSubmit = () => {
      working.value = true

      // if 'show in news feed' not visible then force to 0
      if (!$themeConfig.app.allowShowInFeed.includes(props.wpItemData.content_type)) props.wpItemData.news = 0

      // TODO: Actual update url needs calculating below
      store.dispatch('app/updateContentItem', { contentType, id: router.currentRoute.params.id, contentItemData: props.wpItemData })
        .then(() => {
          router.push({ name: `${contentTypeSingleRouteName(wpContentTypeName.value)}`, params: { id: router.currentRoute.params.id } })
        })
        .catch(error => {
          if (typeof error.response === 'undefined') {
            // log error
            console.error(error)
          } else {
            // alert user to error
            toast({
              component: ToastificationContent,
              props: {
                title: `Error updating ${contentTypeName(wpContentTypeName.value)}.`,
                text: `ERROR: ${error.response.data.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            console.log(error.response.data.message)
          }
        })
    }

    return {
      statusOptions,

      // form
      onSubmit,
      refFormObserver,
      getValidationState,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,

      orgOptions,
      topicOptions,
      eventOptions,
      categoryOptions,
      contentType,
      working,

      $themeConfig,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
