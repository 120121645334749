<template>
  <wp-items-edit
    content-type="kb-articles"
    content-type-singular="kb-article"
    content-name="Knowledge Base Articles"
    content-name-singular="Knowledge Base Article"
  />
</template>

<script>
import WpItemsEdit from '@/views/wp-item/wp-items-edit/WpItemsEdit.vue'

export default {
  components: {
    WpItemsEdit,
  },
}
</script>

<style scoped>

</style>
