<template>
  <div>

    <!-- Header: -->
    <div class="d-flex">
      <feather-icon
        icon="PhotoIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Photos
      </h4>
    </div>

    <b-overlay
      :show="reloading"
      variant="transparent"
      spinner-variant="primary"
      blur="5px"
      opacity=".75"
      rounded="sm"
    >
      <b-row>
        <b-col>
          <ul
            ref="sortableList"
            class="photoList"
          >
            <li
              v-for="photo in contentItemData.content.photos"
              :id="photo.id"
              :key="photo.id"
            >
              <img
                width="300"
                height="300"
                alt="."
                :src="photo.all_sizes.square.url"
              >
              <b-button
                class="photoDelete"
                variant="danger"
                @click="() => deletePhoto(photo.id)"
              >
                Delete
              </b-button>
              <b-form-input
                v-model="photo.alt_tag"
                placeholder="Image caption..."
                @change="updateCaption(photo.id, photo.alt_tag)"
              />
            </li>
          </ul>

          <p v-if="!contentItemData.content.photos.length">
            This gallery currently has no images.
          </p>
        </b-col>
      </b-row>
    </b-overlay>

    <!-- Form:  -->
    <b-form class="mt-1">
      <b-row class="my-3">
        <b-col md="4">
          <b-progress
            v-if="uploading"
            :value="progress"
            variant="primary"
            animated
            class="mt-3"
          />
          <b-form-file
            ref="fileInput"
            placeholder="Click to choose an image or drag a file here..."
            drop-placeholder="Drop file here to upload..."
            accept="image/*"
            multiple
            :file-name-formatter="formatNames"
            :disabled="uploading"
            @input="startUpload"
          />
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormFile, BOverlay, BButton, BProgress, BFormInput
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Sortable from 'sortablejs'
import arrayMove from '@/libs/arrayOrder'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormFile,
    BOverlay,
    BButton,
    BProgress,
    BFormInput,
  },
  props: {
    contentItemData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    uploading: false,
    fileCount: 0,
    progress: 0,
    existingFiles: [],
    reloading: false,
  }),
  watch: {
    contentItemData() {
      this.reloading = false
    },
  },
  mounted() {
    const { content } = this.contentItemData
    const { photos } = content
    // Create a map of the existing files for sorting purposes
    this.existingFiles = photos.map(photo => photo.id)
    this.toast = useToast()

    // make the photo list sortable
    Sortable.create(this.$refs.sortableList, {
      draggable: 'li',
      animation: 80,
      onUpdate: e => {
        this.existingFiles = arrayMove(this.existingFiles, e.oldIndex, e.newIndex)
        this.saveOrder()
      },
    })
  },
  methods: {
    startUpload() {
      if (this.uploading || this.$refs.fileInput.files.length === 0) return
      this.uploading = true
      this.fileCount = this.$refs.fileInput.files.length
      this.progress = (1 - this.$refs.fileInput.files.length / (this.fileCount + 1)) * 100
      this.$refs.fileInput.files.forEach(file => {
        store.dispatch('app/addGalleryPhoto', { id: router.currentRoute.params.id, file })
          .then(() => {
            this.$emit('photoAdded')
            this.reloading = true
          })
          .catch(error => {
            if (typeof error.response === 'undefined') {
              // alert user to error
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error uploading file.',
                  text: `${error}`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
              console.error(error)
            } else {
              // alert user to error
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error uploading file.',
                  text: `ERROR: ${error.response.data.message}`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
              console.error(error.response.data.message)
            }
          })
          .finally(() => {
            // remove file form list
            this.$refs.fileInput.files.splice(this.$refs.fileInput.files.findIndex(item => item.name === file.name), 1)
            this.progress = (1 - this.$refs.fileInput.files.length / (this.fileCount + 1)) * 100
            if (this.$refs.fileInput.files.length === 0) this.uploading = false
          })
      })
    },
    saveOrder() {
      store.dispatch(
        'app/updatePhotoOrder',
        {
          uuid: this.contentItemData.id,
          photoOrder: this.existingFiles.reduce((acc, val, index) => {
            acc[index] = val
            return acc
          }, []),
        },
      )
    },
    deletePhoto(photoId) {
      store.dispatch(
        'app/deletePhoto',
        {
          uuid: this.contentItemData.id,
          photoId,
        },
      )
        .then(() => {
          this.contentItemData.content.photos = [...this.contentItemData.content.photos.filter(val => val.id !== photoId)]
          this.existingFiles = [...this.existingFiles.filter(val => val !== photoId)]
        })
    },
    updateCaption(photoId, caption) {
      store.dispatch(
        'app/updatePhoto',
        {
          uuid: this.contentItemData.id,
          alt_tag: caption,
          photoId,
        },
      )
    },
  },
  setup() {
    const formatNames = fileNames => `${fileNames.length} image(s) uploading...`

    return {
      formatNames,
    }
  },
}
</script>

<style lang="css">
@keyframes nemAnim {
  0%   { background-position: 0 50%; }
  50%  { background-position: 100% 50%; }
  100% { background-position: 0 50%; }
}
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .photoList {
    list-style-type: none;
    padding-left: 0;
    margin-left: -0.5em;
  }

  .photoList li {
    cursor: pointer;
    display: inline-block;
    margin: 0.5em 0.5em;
    position: relative;
  }

  .photoList li .photoDelete {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
  }

  [dir] .custom-file-input[disabled] ~ .custom-file-label, [dir] .custom-file-input:disabled ~ .custom-file-label {
    background: transparent!important;
    border-color: transparent!important;
    &::after {
      opacity: 0;
    }
  }

  [dir] .custom-file-input:hover ~ .custom-file-label {
    background: rgba(255,255,255,0.2);
    border-color: #999;
  }

</style>
